import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import type { Store } from '../types'

type OrderConfirmationState = {
  orderId: number | null
}

type OrderConfirmationActions = {
  setOrderId: (orderId: number | null) => void
}

/**
 * Information about order confirmation
 */
export type OrderConfirmationStore = {
  orderConfirmation: OrderConfirmationState & OrderConfirmationActions
}

// Good practice to keep it still, even while empty
export const initialState: OrderConfirmationState = { orderId: null }

export const orderConfirmationSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  OrderConfirmationStore
> = (set, get) => ({
  orderConfirmation: {
    ...initialState,
    orderId: null,
    setOrderId: (orderId) =>
      set(
        produce(get(), (state: Store) => {
          state.orderConfirmation.orderId = orderId
        }),
      ),
  },
})
