import type { Locale } from 'next-intl'

import type { PriceType } from '@/shared/graphql/schema/commonBackend/graphql'

export const FORBIDDEN_POA_PRICE_TYPES: PriceType[] = ['FIXED', 'HYBRID']

/**
 * Sweden: First possible start date offset in days, for POA agreements
 */
export const START_DATE_OFFSET_POA = 5
/**
 * Sweden: First possible start date offset in days, for NO POA agreements
 */
export const START_DATE_OFFSET_NO_POA = 16

/**
 * Sweden: Last possible date which customer can pick as starting date is calculated base on current date plus this offset in months.
 */
export const SPOT_CONTRACT_LAST_POSSIBLE_DATE_MONTHS_OFFSET = 14

/**
 * Finland: Offset for the start date in days for supplier change
 */
export const FI_START_DATE_SUPPLIER_CHANGE_OFFSET = 16

/**
 * Finland: Offset in days for movers
 */
export const FI_START_DATE_MOVING_OFFSET = 1

/**
 * Finland: Offset in days for product change, if first possible date is not returned by GAPI
 */
export const FI_START_DATE_PRODUCT_CHANGE_OFFSET = 14

/**
 * Finland: Other cases minimum offset in days
 */
export const FI_START_DATE_MIN_FALLBACK_OFFSET = 1

/**
 * Finland: Default max date offset in months
 */
export const FI_MAX_START_DATE_OFFSET = 3

/**
 * The default house letter used in Finland when residence is defined but house letter is not provided.
 */
export const FI_RESIDENCE_DEFINED_DEFAULT_HOUSE_LETTER = 'as.'

/**
 * Format for prepare payload for API, and business logic date comparison
 */
export const DATE_FORMAT_FOR_API = 'yyyy-MM-dd'
export const DATE_FORMAT_COMPARATIVE_PRICE = 'LLL yyyy'

/**
 * Harmonization date format is d MMM yyyy but for some reason Intl have issue with Finnish locale that is why we use d LLL yyyy
 */
export const DATE_COMMON_FORMAT = 'd LLL yyyy'
export const TIME_COMMON_FORMAT = 'T'

/**
 * Common date formats for date picker input only
 */
export const DATE_PICKER_COMMON_FORMAT: Record<Locale, string> = {
  sv: 'yyyy-MM-dd',
  fi: 'dd.MM.yyyy',
  no: 'yyyy-MM-dd',
  en: 'yyyy-MM-dd',
}

export const GTM_CONTRACT_DEFAULT_MAIN_CATEGORY = 'power'

export const SMART_PACKAGE_CONTRACT_ATTRIBUTE = 'SMART_PACKAGE'
