import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import { middleware } from '../middleware'
import type { Store } from '../types'

export type GtmState = {
  itemListName: string
  customerType: string
  listId: string
}

export type GtmActions = {
  setItemListName: (name: string) => void
  setCustomerType: (type: string) => void
  setListId: (id: string) => void
}

export type GtmStore = {
  gtm: GtmState & GtmActions
}

export const initialState: GtmState = {
  itemListName: '',
  customerType: '',
  listId: '',
}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], GtmStore> = (
  set,
  get,
) => ({
  gtm: {
    ...initialState,
    setItemListName: (name) =>
      set(
        produce(get(), (state: Store) => {
          state.gtm.itemListName = name
        }),
      ),
    setCustomerType: (type) =>
      set(
        produce(get(), (state: Store) => {
          state.gtm.customerType = type
        }),
      ),
    setListId: (id) =>
      set(
        produce(get(), (state: Store) => {
          state.gtm.listId = id
        }),
      ),
  },
})

export const createGtmSlice = middleware(creator, (_prevState, _set, _get, _store) => {})
