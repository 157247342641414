'use client'

import { type ReactNode, createContext, useContext, useRef } from 'react'
import { useStore } from 'zustand'

import { createStore } from './store'
import type { Store } from './types'

type UseBoundGlobalStore = ReturnType<typeof createStore>

export const StoreContext = createContext<UseBoundGlobalStore | null>(null)

export type StoreProviderProps = {
  shouldPersist?: boolean
  children: ReactNode
}

export const StoreProvider = ({ children, shouldPersist = true }: StoreProviderProps) => {
  const storeRef = useRef<UseBoundGlobalStore>(undefined)
  if (!storeRef.current) {
    storeRef.current = createStore(shouldPersist)
  }

  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>
}

export const useGlobalStore = <T,>(selector: (store: Store) => T): T => {
  const storeContext = useContext(StoreContext)

  if (!storeContext) {
    throw new Error(`useStore must be use within StoreProvider`)
  }

  return useStore(storeContext, selector)
}

export const useGlobalStoreContext = () => {
  const storeContext = useContext(StoreContext)
  return storeContext
}
