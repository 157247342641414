import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import { getCustomerInfoWithBankIdCode } from '@/open-web/components/Checkout/Steps/IdentificationStep/SwedishIdentificationStep/utils'
import { FORBIDDEN_POA_PRICE_TYPES } from '@/open-web/utils/constants'
import type { AvailableEInvoiceBanksQuery } from '@/shared/graphql/schema/commonBackend/graphql'
import { vanillaTRPCClient } from '@/shared/trpc/react'
import { GlobalApiError } from '@/shared/utils/errorClasses'

import { middleware } from '../middleware'
import type { Store } from '../types'
import { initialState as dataInitialState } from './checkoutDataSlice'
import { initialState as customerInitialState } from './customerSlice'
import { initialState as enterpriseCustomerInitialState } from './enterpriseCustomerSlice'
import type { CustomerType } from './selectedContractSlice'

//Flow Types
export type StepName = 'identification' | 'delivery' | 'billing' | 'contact'
export type StepStatus = 'active' | 'completed' | 'current' | 'disabled' | 'edit'
export type StepState = Record<StepName, StepStatus> & {
  isAnyStepEdited: boolean
}

export const CreditCheckStatuses = {
  Green: 'Green',
  Red: 'Red',
  Yellow: 'Yellow',
} as const

export type CreditCheckStatus = keyof typeof CreditCheckStatuses

export type IdentificationFlow = {
  failedAttemptsList: ManualIdentificationParams[]
  bankIdState?: SimpleStateMachine
  manualFetchState?: SimpleStateMachine
  tokenCode?: string
  wasDataFetched: boolean
}

export type DeliveryFlow = {
  //TODO: Move to checkoutDataSlice
  deliveryAddressType?: DeliveryAddressType
  isEarlyStartupTermsAccepted?: boolean
  shouldRenderEarlyStartupTerms?: boolean
}

export type DeliveryAddressType = 'registry' | 'moving' | 'another'

export type BillingFlow = {
  billingAddressSource: 'delivery' | 'custom'
  bankList: AvailableEInvoiceBanksQuery['availableEInvoiceBanks']
  isBankListError?: boolean
}

type SimpleStateMachine = 'loading' | 'error'

export type ManualIdentificationParams = {
  ssn: string
  postalCode: string
}

export type CheckoutFlowState = {
  stepState: StepState
  isMultipleEditError: boolean
  identification: IdentificationFlow
  delivery: DeliveryFlow
  billing: BillingFlow
  isContractTermsAccepted: boolean
  isEarlyStartupTermsAccepted: boolean
  shouldRenderEarlyStartupTerms: boolean
  isAllowedToMakeContractAccepted: boolean
  isSpotContractTermsAccepted: boolean
  isTermsDisabled: boolean
  creditCheckStatus?: CreditCheckStatus
}

export type CheckoutFlowActions = {
  resetCheckout: (customerType: CustomerType) => void
  setStepStatus: (step: StepName, status: StepStatus) => void
  setIsMultipleEditError: (isError: boolean) => void

  identification: {
    addFailedAttempt: (ssn: string, postalCode: string) => void
    setBankIdState: (newState: SimpleStateMachine) => void
    clearBankIdState: () => void
    setManualFetchState: (newState: SimpleStateMachine) => void
    clearManualFetchState: () => void
    setTokenCode: (code?: string) => void
    setWasDataFetched: (wasFetched: boolean) => void
    resetBankIdHelperValues: () => void
  }
  delivery: {
    setDeliveryAddressType: (type: DeliveryFlow['deliveryAddressType']) => void
    setIsEarlyStartupTermsAccepted: (value: boolean) => void
    setShouldRenderEarlyStartupTerms: (value: boolean) => void
  }
  billing: {
    setAddressSource: (source: BillingFlow['billingAddressSource']) => void
    setBankList: (bankList: AvailableEInvoiceBanksQuery['availableEInvoiceBanks']) => void
    setIsBankListError: (isError: boolean) => void
  }

  setIsContractTermsAccepted: (value: boolean) => void
  setIsEarlyStartupTermsAccepted: (value: boolean) => void
  setShouldRenderEarlyStartupTerms: (value: boolean) => void
  setIsAllowedToMakeContractAccepted: (value: boolean) => void
  setIsSpotContractTermsAccepted: (value: boolean) => void
  setCreditCheckStatus: (status: CreditCheckStatus) => void
}

export type CheckoutFlowStore = {
  checkoutFlow: CheckoutFlowState & CheckoutFlowActions
}

export const initialStepState: StepState = {
  identification: 'active',
  delivery: 'disabled',
  billing: 'disabled',
  contact: 'disabled',
  isAnyStepEdited: false,
}

export const initialIdentificationState: CheckoutFlowState['identification'] = {
  failedAttemptsList: [],
  wasDataFetched: false,
}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], CheckoutFlowStore> = (
  set,
  get,
) => ({
  checkoutFlow: {
    stepState: initialStepState,
    isMultipleEditError: false,
    isTermsDisabled: true,
    resetCheckout: (customerType) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.stepState = initialStepState
          state.checkoutFlow.billing.billingAddressSource = 'delivery'
          state.checkoutFlow.delivery.deliveryAddressType = state.selectedContract[customerType]
            .campaignConfiguration?.isMoving
            ? 'moving'
            : 'registry'
          state.checkoutFlow.identification.failedAttemptsList =
            initialIdentificationState.failedAttemptsList
          state.checkoutFlow.identification.wasDataFetched =
            initialIdentificationState.wasDataFetched
          state.checkoutFlow.isContractTermsAccepted = false
          state.checkoutFlow.isEarlyStartupTermsAccepted = false
          state.checkoutFlow.shouldRenderEarlyStartupTerms = false
          state.checkoutFlow.isAllowedToMakeContractAccepted = false
          state.checkoutFlow.isSpotContractTermsAccepted = false
          state.checkoutFlow.isTermsDisabled = true

          state.customer = { ...state.customer, ...customerInitialState }
          state.enterpriseCustomer = {
            ...state.enterpriseCustomer,
            ...enterpriseCustomerInitialState,
          }
          state.checkoutData.consents = dataInitialState.consents
          state.checkoutData.delivery = dataInitialState.delivery
          state.checkoutData.billing = dataInitialState.billing

          const { contractTemplate } = state.selectedContract[customerType]
          state.checkoutData.meter =
            contractTemplate && FORBIDDEN_POA_PRICE_TYPES.includes(contractTemplate.priceType)
              ? { ...dataInitialState.meter, meterInformationSource: 'manualInput' }
              : dataInitialState.meter
        }),
      ),
    setStepStatus: (step, status) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.stepState.isAnyStepEdited = status === 'edit'
          state.checkoutFlow.stepState = { ...state.checkoutFlow.stepState, [step]: status }

          const { stepState } = state.checkoutFlow
          state.checkoutFlow.isTermsDisabled =
            stepState.isAnyStepEdited || stepState.contact !== 'completed'
        }),
      ),
    setIsMultipleEditError: (isError) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.isMultipleEditError = isError
        }),
      ),
    identification: {
      ...initialIdentificationState,
      addFailedAttempt: (ssn, postalCode) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.failedAttemptsList = [
              ...state.checkoutFlow.identification.failedAttemptsList,
              {
                ssn: ssn,
                postalCode: postalCode,
              },
            ]
          }),
        ),
      setBankIdState: (newState) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.bankIdState = newState
          }),
        ),
      clearBankIdState: () =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.bankIdState = undefined
          }),
        ),
      setManualFetchState: (newState) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.manualFetchState = newState
          }),
        ),
      clearManualFetchState: () =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.manualFetchState = undefined
          }),
        ),
      setTokenCode: (code) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.tokenCode = code
          }),
        ),
      setWasDataFetched: (wasFetched) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.wasDataFetched = wasFetched
          }),
        ),
      resetBankIdHelperValues: () =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.identification.tokenCode = undefined
            state.checkoutFlow.identification.wasDataFetched = false
          }),
        ),
    },
    delivery: {
      deliveryAddressType: 'registry',
      setDeliveryAddressType: (type) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.delivery.deliveryAddressType = type
          }),
        ),
      setIsEarlyStartupTermsAccepted: (value: boolean) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.delivery.isEarlyStartupTermsAccepted = value
          }),
        ),
      setShouldRenderEarlyStartupTerms: (value: boolean) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.delivery.shouldRenderEarlyStartupTerms = value
          }),
        ),
    },
    billing: {
      billingAddressSource: 'delivery',
      bankList: [],
      setAddressSource: (source) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.billing.billingAddressSource = source
          }),
        ),
      setBankList: (bankList) =>
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.billing.bankList = bankList
          }),
        ),
      setIsBankListError: (isError) => {
        set(
          produce(get(), (state: Store) => {
            state.checkoutFlow.billing.isBankListError = isError
          }),
        )
      },
    },
    isContractTermsAccepted: false,
    isEarlyStartupTermsAccepted: false,
    shouldRenderEarlyStartupTerms: false,
    isAllowedToMakeContractAccepted: false,
    isSpotContractTermsAccepted: false,
    setIsContractTermsAccepted: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.isContractTermsAccepted = value
        }),
      ),
    setIsEarlyStartupTermsAccepted: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.isEarlyStartupTermsAccepted = value
        }),
      ),
    setShouldRenderEarlyStartupTerms: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.shouldRenderEarlyStartupTerms = value
        }),
      ),
    setIsAllowedToMakeContractAccepted: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.isAllowedToMakeContractAccepted = value
        }),
      ),
    setIsSpotContractTermsAccepted: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.isSpotContractTermsAccepted = value
        }),
      ),
    setCreditCheckStatus: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutFlow.creditCheckStatus = value
        }),
      ),
  },
})

export const createCheckoutFlowSlice = middleware(creator, (_prevState, _set, get, _store) => {
  const { setStepStatus } = get().checkoutFlow
  const {
    tokenCode,
    wasDataFetched,
    clearBankIdState,
    resetBankIdHelperValues,
    setBankIdState,
    setWasDataFetched,
  } = get().checkoutFlow.identification
  const { setCustomer } = get().customer
  if (!wasDataFetched && tokenCode) {
    setWasDataFetched(true)
    setBankIdState('loading')

    getCustomerInfoWithBankIdCode(tokenCode)
      .then((customer) => {
        const {
          address,
          isExistingCustomer,
          birthdate: _birthdate,
          firstName: _firstName,
          lastName: _lastName,
          ssn: _ssn,
        } = customer
        setCustomer({ _birthdate, _firstName, _lastName, _ssn }, address, isExistingCustomer)
        clearBankIdState()
        setStepStatus('identification', 'completed')
        setStepStatus('delivery', 'active')
      })
      .catch(() => setBankIdState('error'))
      .finally(() => resetBankIdHelperValues())
  }

  const { bankList, isBankListError, setBankList, setIsBankListError } = get().checkoutFlow.billing
  if (bankList.length === 0 && !isBankListError) {
    if (process.env.STORYBOOK) {
      return
    }

    void vanillaTRPCClient.openWeb.availableEInvoiceBanks
      .query()
      .then((result) => {
        const { data, error } = result

        if (error) {
          throw new GlobalApiError({ apiError: error })
        }

        if (data?.length) {
          setBankList(data)
        } else {
          setIsBankListError(true)
        }
      })
      .catch(() => setIsBankListError(true))
  }
})
