import { createTRPCProxyClient, createTRPCReact, httpLink } from '@trpc/react-query'
import superjson from 'superjson'

import type { AppRouter } from '@/shared/trpc/routers/app'

import { countryConfig } from '../countryConfig'

export const trpc = createTRPCReact<AppRouter>()

// Adding default locale for now.
const url = `${countryConfig.basePath}/api/trpc/`

// This client can only be used in the client components!
export const vanillaTRPCClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpLink({
      url,
    }),
  ],
  transformer: superjson,
})
