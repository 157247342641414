import { browserEnvs } from '../envs'
import type { CheckoutDataState } from './slices/checkoutDataSlice'
import type {
  Address,
  ContactInformation,
  Enterprise,
  EnterpriseContactInformation,
  Person,
} from './types'

export const ENTERPRISE_DEFAULTS: Enterprise = {
  _name: '',
  _orgId: '',
}

export const PERSON_DEFAULTS: Person = {
  _firstName: '',
  _lastName: '',
  _birthdate: '',
  _ssn: '',
}

export const ADDRESS_DEFAULTS: Address = {
  streetName: '',
  city: '',
  postalCode: '',
  houseNumber: '',
  residence: '',
  houseLetter: '',
}

export const CONTACT_INFORMATION_DEFAULTS: ContactInformation = {
  email: '',
  phoneNumber: '',
}

export const ENTERPRISE_CONTACT_INFORMATION_DEFAULTS: EnterpriseContactInformation = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
}

export const getBillingInitialState = (): CheckoutDataState['billing'] => {
  switch (browserEnvs.NEXT_PUBLIC_COUNTRY) {
    case 'FI':
      return {
        address: { country: 'FI', ...ADDRESS_DEFAULTS },
        method: 'post',
        postDeliveryMethod: 'streetAddress',
        shouldSendCombinedInvoice: true,
      }
    case 'SE':
    case 'NO':
    case 'GB':
    default:
      return {
        address: ADDRESS_DEFAULTS,
        method: 'e-invoice',
        postDeliveryMethod: null,
      }
  }
}
