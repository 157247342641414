import Personnumber from 'personnummer'

import { getBankIdUserToken } from '@/open-web/services/getBankIdUserToken'
import { getCustomerInfoByBank } from '@/open-web/services/getCustomerInfoByBank'
import { getCustomerInfoBySsn } from '@/open-web/services/getCustomerInfoBySsn'
import { getEnterpriseCustomerInfoById } from '@/open-web/services/getEnterpriseCustomerInfoByOrgId'
import { countryConfig } from '@/shared/countryConfig'
import { GQLErrorCodes } from '@/shared/graphql/gqlErrorCodes'
import type {
  CustomerInfoByBankQuery,
  CustomerInfoBySsnQuery,
  EnterpriseCustomerInfoByOrgIdQuery,
} from '@/shared/graphql/schema/commonBackend/graphql'
import { routes } from '@/shared/routes'
import type { ManualIdentificationParams } from '@/shared/store/slices/checkoutFlowSlice'
import type { Address } from '@/shared/store/types'
import { logError } from '@/shared/utils/error'
import { GlobalApiError, UnknownGlobalApiError } from '@/shared/utils/errorClasses'

export const getCustomerInfoWithBankIdCode = async (code: string) => {
  const token = await getBankIdUserToken(code, routes.CHECKOUT)

  if (!token.access_token) {
    throw logError(new Error('Failed to fetch access token'))
  }

  const { data: identificationByBank, error } = await getCustomerInfoByBank(token.access_token)

  if (error) {
    throw logError(new GlobalApiError({ apiError: error }))
  }

  if (!identificationByBank) {
    throw logError(new UnknownGlobalApiError('getCustomerInfoByBank data and error are empty'))
  }

  return parseIdentificationData(identificationByBank)
}

export type Customer = {
  firstName: string
  lastName: string
  ssn: string
  birthdate: string
  address: Address
  isExistingCustomer: boolean
}
export type EnterpriseCustomer = Pick<
  EnterpriseCustomerInfoByOrgIdQuery['identificationByOrgId'],
  'name' | 'orgId'
> & { address: Address }

export const getClientData = async (ssn: string, postalCode: string): Promise<Customer> => {
  const { data: identificationBySsn, error } = await getCustomerInfoBySsn(ssn, postalCode)

  if (error) {
    throw logError(new GlobalApiError({ apiError: error }))
  }

  if (!identificationBySsn) {
    throw logError(new UnknownGlobalApiError('getCustomerInfoBySsn data and error are empty'))
  }

  return parseIdentificationData(identificationBySsn)
}

export const getEnterpriseClientData = async (
  ordId: string,
  postalCode: string,
): Promise<EnterpriseCustomer> => {
  const { data: identificationByOrdId, error } = await getEnterpriseCustomerInfoById(
    ordId,
    postalCode,
  )

  if (error) {
    throw logError(new GlobalApiError({ apiError: error }))
  }

  if (!identificationByOrdId) {
    throw logError(
      new UnknownGlobalApiError('getEnterpriseCustomerInfoById data and error are empty'),
    )
  }

  return parseEnterpriseIdentificationData(identificationByOrdId)
}

type IdentificationData =
  | CustomerInfoByBankQuery['identificationByBank']
  | CustomerInfoBySsnQuery['identificationBySsn']

const parseIdentificationData = (data: IdentificationData): Customer => {
  const { address, ssn, existingCustomer: isExistingCustomer, firstName, lastName } = data

  if (!firstName || !lastName) {
    // TODO: fix it
    throw new Error('First or last name were not provided by identity provider')
  }

  return {
    firstName,
    lastName,
    isExistingCustomer,
    address: parseRegisterAddress(address),
    ssn,
    birthdate: getBirthDateFromSsn(ssn),
  }
}

const parseEnterpriseIdentificationData = (
  data: EnterpriseCustomerInfoByOrgIdQuery['identificationByOrgId'],
): EnterpriseCustomer => {
  const { address, orgId, name } = data

  return {
    address: parseRegisterAddress(address),
    name,
    orgId,
  }
}

const parseRegisterAddress = (address: IdentificationData['address']) => {
  if (address) {
    const { zipCode, cityName, streetName, houseLetter, houseNumber, residence } = address

    const house =
      `${houseNumber == null ? '' : houseNumber} ${houseLetter == null ? '' : houseLetter}`.trim()

    return {
      postalCode: zipCode,
      city: cityName,
      streetName,
      houseNumber: house,
      residence: residence === null ? undefined : residence,
    }
  }

  // TODO: Give a description
  throw new Error('Missing address')
}

const getBirthDateFromSsn = (ssn: string): string => {
  const { fullYear, month, day } = new Personnumber(ssn)

  return `${fullYear}-${month}-${day}`
}

export const clearBankIdSearchParams = (path: string) => {
  const url = `${countryConfig.basePath}${path}`
  window.history.replaceState({ ...window.history.state, as: url, url: url }, '', url)
}

export const wasDataTried = (
  failedAttempts: ManualIdentificationParams[],
  currentAttempt: ManualIdentificationParams,
) => {
  return failedAttempts.some(
    (pastAttempt) =>
      pastAttempt.ssn === currentAttempt.ssn &&
      pastAttempt.postalCode === currentAttempt.postalCode,
  )
}

export const isKnownErrorGlobalApiError = (error: unknown) => {
  return (
    logError(error as GlobalApiError<unknown>)?.code === GQLErrorCodes.NOT_FOUND ||
    (error as GlobalApiError<unknown>)?.code === GQLErrorCodes.SSN_MINOR
  )
}
