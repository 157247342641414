import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import type { ConsentChange } from '@fortum/global-consent-components'

import type {
  ContractsValidationProcess,
  ContractsValidationStatus,
  DeliverySiteAddressStatus,
} from '@/shared/graphql/schema/commonBackend/graphql'

import { ADDRESS_DEFAULTS, getBillingInitialState } from '../defaults'
import { middleware } from '../middleware'
import type { Address, Store } from '../types'

//Payload Types
export type InvoiceMethod = 'e-invoice' | 'post' | 'email'
export type PostDeliveryMethod = 'streetAddress' | 'postBox' | 'generalDelivery' | null

type MeterInformationSource = 'poa' | 'manualInput'
export type HasActiveContractOnDeliveryAnswer = 'yes' | 'no' | ''

export type Bank = {
  bankName: string
  bic: string
}

export type CheckoutDataState = {
  consents: ConsentChange[]
  delivery: {
    startDate?: string
    movingFromAddress?: Address
    contractValidationProcess?: ContractsValidationProcess
    contractValidationStatus?: ContractsValidationStatus
    /**
     * Finnish only attribute: when GAPI is not able to get an information about the delivery site address
     */
    hasActiveContractOnDeliveryAnswer: HasActiveContractOnDeliveryAnswer
    deliverySiteAddressValidationStatus?: DeliverySiteAddressStatus
    /**
     * Finnish only attribute: GAPI information about the special treatment for the grid company - eg. Caruna
     */
    isDesignatedSupplier?: boolean
  }
  billing: {
    method: InvoiceMethod
    address?: Address
    bank?: Bank
    postDeliveryMethod?: PostDeliveryMethod
    /**
     * Finnish only attribute We ask only designatedSupplier is set to true (business logic is in GAPI)
     */
    shouldSendCombinedInvoice?: boolean
  }
  meter: {
    address: Address
    meterInformationSource?: MeterInformationSource
    gridOwnerCode?: string
    meterPointId?: string
    meterPointNo?: string
  }
}

type CheckoutDataActions = {
  setConsents: (consents: ConsentChange[]) => void
  setMeter: (meter: CheckoutDataState['meter']) => void
  setMeterInformationSource: (meterInformationSource: MeterInformationSource) => void
  setMovingFromAddress: (address: Address) => void
  setContractValidationProcess: (process?: ContractsValidationProcess) => void
  setContractValidationStatus: (status?: ContractsValidationStatus) => void
  setIsDesignatedSupplier: (value: boolean) => void
  setDeliverySiteAddressValidationStatus: (status?: DeliverySiteAddressStatus) => void
  setHasActiveContractOnDeliveryAnswer: (answer: HasActiveContractOnDeliveryAnswer) => void
  setStartDeliveryDate: (startDate?: string) => void
  setBillingAddress: (address?: Address) => void
  setBillingBank: (bank?: Bank) => void
  setBillingMethod: (method: InvoiceMethod) => void
  setPostDeliveryMethod: (method: PostDeliveryMethod) => void
  setShouldSendCombinedInvoice: (value: boolean) => void
}

export type CheckoutDataStore = {
  checkoutData: CheckoutDataState & CheckoutDataActions
}

export const initialState: CheckoutDataState = {
  consents: [],
  delivery: {
    hasActiveContractOnDeliveryAnswer: '',
  },
  billing: getBillingInitialState(),
  meter: {
    address: ADDRESS_DEFAULTS,
    meterInformationSource: undefined,
    gridOwnerCode: undefined,
    meterPointId: undefined,
  },
}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], CheckoutDataStore> = (
  set,
  get,
) => ({
  checkoutData: {
    ...initialState,
    setConsents: (consents) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.consents = consents
        }),
      ),
    setMeter: (meter) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.meter = meter
        }),
      ),
    setMeterInformationSource: (meterInformationSource) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.meter.meterInformationSource = meterInformationSource
        }),
      ),
    setMovingFromAddress: (address) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery.movingFromAddress = address
        }),
      ),
    setContractValidationProcess: (process) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery.contractValidationProcess = process
        }),
      ),
    setContractValidationStatus: (status) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery.contractValidationStatus = status
        }),
      ),
    setIsDesignatedSupplier: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery.isDesignatedSupplier = value
        }),
      ),
    setDeliverySiteAddressValidationStatus: (status) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery.deliverySiteAddressValidationStatus = status
        }),
      ),
    setHasActiveContractOnDeliveryAnswer: (answer) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery.hasActiveContractOnDeliveryAnswer = answer
        }),
      ),
    setStartDeliveryDate: (startDate) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery.startDate = startDate
        }),
      ),
    setBillingAddress: (address) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing.address = address
        }),
      ),
    setBillingBank: (bank) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing.bank = bank
        }),
      ),
    setBillingMethod: (method) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing.method = method
        }),
      ),
    setPostDeliveryMethod: (postDeliveryMethod) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing.postDeliveryMethod = postDeliveryMethod
        }),
      ),
    setShouldSendCombinedInvoice: (value) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing.shouldSendCombinedInvoice = value
        }),
      ),
  },
})

export const createCheckoutDataSlice = middleware(creator, (_prevState, _set, _get, _store) => {})
